<template>
  <en-dialog :model-value="modelValue" width="80%" title="对账单列表" @close="$emit('update:model-value', false)">
    <template #operation>
      <button-ajax v-if="store.accessRightsHash.PAINT_STATEMENT_ENOCH_EXPORT" link :method="operation.export.click">导出</button-ajax>
      <en-button v-if="store.accessRightsHash.PAINT_STATEMENT_RECEIPT_ENOCH" type="primary" link @click="operation.receipt.click">一键收款</en-button>
    </template>

    <table-dynamic
      :height="600"
      code="SPBSNSTATFD"
      :data="table.data"
      :loading="table.loading"
      :paging="table.paging"
      :method="store.userTenantId === 'ENOCH' ? table.getByEnoch : table.getByTenant"
      pagination
      :config="table.config"
      show-selectable
      @selection-change="table.selection.change"
    >
    </table-dynamic>
  </en-dialog>

  <en-dialog v-model="receipt.visible" title="一键收款">
    <en-table :data="receipt.data" :laoding="receipt.loading">
      <en-table-column label="对账单号" prop="paintBusinessStatement.serialNo"></en-table-column>
      <en-table-column label="网点" prop="paintBusinessStatement.tenantName"></en-table-column>
      <en-table-column label="对账总金额" prop="business.totalAmount">
        <template #default="{ row }: { row: EnosprayStatementDefinitions['PaintBusinessStatementReceiptDto'] }">
          {{ formatMoney(row.paintBusinessStatement?.totalAmount) }}
        </template>
      </en-table-column>
      <en-table-column label="结算总金额" prop="business.settlementAmount">
        <template #default="{ row }: { row: EnosprayStatementDefinitions['PaintBusinessStatementReceiptDto'] }">
          {{ formatMoney(row.paintBusinessStatement?.settlementAmount) }}
        </template>
      </en-table-column>
      <en-table-column label="实收金额" prop="amount">
        <template #default="{ row }: { row: EnosprayStatementDefinitions['PaintBusinessStatementReceiptDto'] }">
          <en-input-number v-model="row.amount" :min="0" :max="row.paintBusinessStatement?.settlementAmount" class="w-full"></en-input-number>
        </template>
      </en-table-column>
    </en-table>

    <template #footer>
      <en-button @click="receipt.footer.cancel.click">取消</en-button>
      <button-ajax :method="receipt.footer.confirm.click">确定</button-ajax>
    </template>
  </en-dialog>
</template>

<script lang="ts">
import { EnMessage } from '@enocloud/components'
import { calculator } from '@enocloud/utils'

export default factory({
  props: {
    modelValue: Boolean
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  config: {
    children: {
      operation: {
        export: {
          click() {
            return this.ajax('GET /enospray/statement/paint/business/enoch/export', this.table.$ajaxParams)
          }
        },
        receipt: {
          click() {
            if (!this.table.selection.data.length) return EnMessage.warning('请勾选对账单')
            this.receipt.data = this.table.selection.data.map((item) => {
              return {
                paintBusinessStatement: item,
                amount: calculator.sub(item.settlementAmount, item.receiptAmount)
              } as unknown as EnosprayStatementDefinitions['PaintBusinessStatementReceiptDto']
            })
            this.receipt.visible = true
          }
        }
      },
      table: {
        ajax: {
          getByTenant: {
            action: 'GET /enospray/statement/paint/business/query',
            data: 'array',
            loading: true,
            pagination: true
          },
          getByEnoch: {
            action: 'GET /enospray/statement/paint/business/enoch/query',
            data: 'array',
            loading: true,
            pagination: true
          }
        },
        config: {},
        children: {
          selection: {
            data: [] as EnosprayStatementDefinitions['PaintBusinessStatementQueryDto'][],
            change(rows: EnosprayStatementDefinitions['PaintBusinessStatementQueryDto'][]) {
              this.table.selection.data = rows
            }
          }
        }
      },
      receipt: {
        visible: false,
        data: [] as EnosprayStatementDefinitions['PaintBusinessStatementReceiptDto'][],
        ajax: {
          submit: {
            action: 'POST /enospray/statement/paint/business/receipt/enoch',
            loading: true,
            params(params) {
              params.data = this.receipt.data
            }
          }
        },
        children: {
          footer: {
            cancel: {
              click() {
                this.receipt.visible = false
                this.receipt.data = []
              }
            },
            confirm: {
              async click() {
                await this.receipt.submit()
                this.receipt.visible = false
                return this.table[this.store.userTenantId === 'ENOCH' ? 'getByEnoch' : 'getByTenant']()
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table[this.store.userTenantId === 'ENOCH' ? 'getByEnoch' : 'getByTenant']()
  }
})
</script>
